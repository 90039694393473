
.content {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 12px;
}

@media only screen and (max-width: 768px) {
  .content {
    grid-template-columns: 88px 1fr;
  }
}

.dates {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  cursor: pointer;
  position: relative;
  perspective: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 160px;
}

.dates::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background: #000;
  transform: rotate3d(0, 0, 1, 45deg);
  left: -10px;
}

.date {
  padding: 5px 10px;
  transform-style: preserve-3d;
  position: absolute;
  width: 100%;
  color: #BDCA44;
  text-align: center;
  transition: all .3s ease;
  font-size: 1.2rem;
} 

.carrousel {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: max-content;
}

.prev-date {
  transform: rotateX(30deg) translate3d(0px, 0px, 80px);
}

.prev-prev-date {
  transform: rotateX(60deg) translate3d(0px, 0px, 80px);
}

.actual-date {
  transform: rotateX(0deg) translate3d(0px, 0px, 80px);
}

.next-date {
  transform: rotateX( -30deg ) translate3d(0px, 0px, 80px);
}

.next-next-date {
  transform: rotateX( -60deg ) translate3d(0px, 0px, 80px);
}

.no-show {
  opacity: 0;
  display: none;
}

.actions {
  text-align: end;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.actions button:first-child {
  margin-right: 12px;
}

.button-prev,
.button-next{
  background-color: #000;
  opacity: .75;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-prev:hover,
.button-next:hover {
  opacity: 1;
}

.button-prev,
.button-next::after {
  font-size: 16px;
  font-family: swiper-icons;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

button.button-prev::after {
  content: "prev";
}

button.button-next::after {
  content: "next";
}